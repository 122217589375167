:root {
  --ref-card-header: #202223;
  --ref-card-bg: #ffffff;
  --ref-offer: #6d7175;
  --ref-input-border: #babfc3;
  --ref-disc-btn: #84c444;
  --rating-bg: #ffc803;
  --rating-num-count: #141f27;
  --vert-rev-btn: #84c444;
  --font32: 32px;
  --font15: 15px;
  --font14: 14px;
  --font13: 13px;
  --font12: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.referral-card {
  padding: 32px;
  width: 613px;
  background-color: var(--ref-card-bg);
  border-radius: 6px;
  /* width: fit-content; */
}

.referral-card .ref-header {
  display: flex;
  justify-content: space-between;
}

.referral-card .ref-header .gift-header {
  font-family: sf-pro-display;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font32);
  color: var(--ref-card-header);
  margin: 0px;
}

.referral-card .ref-header svg {
  position: relative;
  bottom: 18px;
  left: 18px;
}

.referral-card .ref-offer {
  font-family: sf-pro-text;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font15);
  color: var(--ref-offer);
  padding: 12px 0px 16px 0px;
  margin: 0px;
}

.referral-card .email-input {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px 0px;
}

.referral-card .email-input label,
.referral-card .name-input label {
  font-family: sf-pro-text;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font14);
  color: var(--ref-card-header);
}

.referral-card .email-input span,
.referral-card .name-input span {
  color: red;
  font-size: var(--font14);
}

.referral-card .email-input input,
.referral-card .name-input input {
  padding: 8px 8px 8px 12px;
  border: 1px solid var(--ref-input-border);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.referral-card .email-input input:focus,
.referral-card .name-input input:focus {
  outline: none;
}

.referral-card .email-input input::placeholder,
.referral-card .name-input input::placeholder {
  font-family: sf-pro-text;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font14);
  opacity: 0.5;
}

.referral-card .name-input {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 32px;
}

.referral-card .button-style {
  background: var(--ref-disc-btn);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
    inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  width: 100%;
  padding: 8px 0px;
  font-family: sf-pro-text;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--ref-card-bg);
  cursor: pointer;
}

.referral-card .terms-cond {
  font-family: sf-pro-text;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font14);
  color: var(--ref-offer);
  margin: 0px;
  padding: 16px 0px;
  text-align: center;
}

.referral-card .terms-cond a {
  font-family: sf-pro-text;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font14);
  color: var(--ref-offer);
}

.body-ref {
  padding-bottom: 0;
  height: 99vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.disc-card {
  border: 0px !important;
  padding: 0px !important;
}

/* popup container */

.popup-one-container {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  position: relative;
  max-width: 573px;
  margin: 0 auto;
}

.popup-one-container .popup-one .popup-one-header .popup-one-x {
  text-align: end;
  position: absolute;
  right: 20px;
  top: 20px;
}

.popup-one-container .popup-one .order-confirm {
  display: flex;
  align-items: center;
  padding-bottom: 45px;
}

.popup-one-container .popup-one .order-confirm .order-num-name {
  padding-left: 20px;
}

.popup-one-container .popup-one .order-confirm .order-num-name .order-num {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18.2821px;
  line-height: 16px;
  color: #555555;
  padding-bottom: 15px;
}

.popup-one-container .popup-one .order-confirm .order-num-name .order-name {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 29.2513px;
  line-height: 26px;
  color: #202223;
}

.popup-one-container .popup-one .popup-one-coupon {
  text-align: center;
  background-color: #84c444;
  border-radius: 25px 25px 0px 0px;
}

.popup-one-container .popup-one .popup-one-coupon .coupon-top {
  padding: 25px 33px;
}

.popup-one-container .popup-one .popup-one-coupon .coupon-border {
  border-bottom: 2px dashed #ffffff;
}

.popup-one-container .popup-one .popup-one-coupon .give-get {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 31px;
  color: #ffffff;
}

.popup-one-container .popup-one .popup-one-coupon .give-gift {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22.907px;
  line-height: 29px;
  color: #ffffff;
}

.popup-one-container .popup-one .popup-one-coupon .use-discount {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding-top: 11px;
}

.popup-one-container .popup-one .coupon-border {
  display: flex;
  justify-content: space-between;
}

.popup-one-container .popup-one .coupon-border .eclipse-border-left {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;
  top: 15px;
  right: 18px;
}

.popup-one-container .popup-one .coupon-border .eclipse-border-right {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;
  top: 15px;
  left: 18px;
}

.popup-one-container .popup-one .coupon-bottom {
  padding: 33px 33px 20px 33px;
}

.popup-one-container .popup-one .coupon-bottom button {
  background: #ffffff;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 21.7539px;
  line-height: 26px;
  color: #000000;
  border: 1px solid #ffffff;
  border-radius: 10.9692px;
  width: 100%;
  padding: 17px 0px;
  cursor: pointer;
}

.popup-one-container .popup-one .popup-one-coupon .eclipse-bottom {
  position: relative;
  display: flex;
  gap: 9px;
  padding: 0px 2px;
  top: 20px;
}

.popup-one-container .popup-one .popup-one-coupon .eclipse-bottom .eclipse {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
}

.popup-one-container .popup-one .popup-one-coupon .copy-code {
  display: flex;

  justify-content: center;
}

.popup-one-container .popup-one .popup-one-coupon .code {
  font-family: sf-pro-display;
  font-style: normal;
  font-weight: 400;
  background-color: white;
  font-size: 12px;
  border: 2px dashed #e2f1ea;
  border-right: none;
  text-align: left;
  border-radius: 4px 0px 0px 4px;
  padding: 10px 26px;
  max-width: 260px;
  word-wrap: break-word;
}
.popup-one-container .popup-one .popup-one-coupon .copy {
  background: #ecf3e6;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #ecf3e6;
  font-family: sf-pro-text;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  padding: 14px 24px;
  cursor: pointer;
}
.c-pointer {
  cursor: pointer;
}

.error {
  border-color: #d82c0d !important;
}
.rcf-swiper-maindiv {
  padding: 20px;
}
.rcf-swiper .swiper-wrapper{
  min-height: 100vh;
}

.rate-popup-container .rate-popup .popup-content button {
  background-color: transparent;
  all: unset;
}

.rate-popup-container .rate-popup .popup-content button.star-active-check {
  opacity: 1;
  transform: scale(1); 
  transition: 
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out,
    fill 0.3s ease-in-out, 
    stroke 0.3s ease-in-out;
}

.rate-popup-container .rate-popup .popup-content button.star-active-check:hover {
  opacity: 1; 
  transform: scale(1.3);
}

.rate-popup-container .rate-popup .popup-content button.star-active-check.active svg path {
  fill: var(--star-hover-color);
  stroke:var(--star-hover-color);
}

.new-clear-filter{
  color: #000;
  /* font-family: "SF Pro Text"; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  display: flex;
  /* height: 10px; */
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  /* padding-top: 10px; */
}

.new-sort-filter-item,.new-filter-select{
  cursor: pointer;
  display: flex;
  height: 20px;
  padding: 0px 10px;
  align-items: center;
  gap: 12px;
  color: #000;
  /* font-family: "SF Pro Text"; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new-sort-filter-item:hover{
  background-color: #e2e2e2;
}
.new-sort-filter-item span{
  display: flex;
  align-items: center;
  gap: 4px;
}
.new-sort-filter-item span div{

}


.no-hover{
  /* background-color: transparent; */
}

.no-hover:hover{
  cursor: pointer;
  padding-left: 2px;
}


.filter-attribute-heading{
  display: flex;
  padding: 4px 10px !important;
  align-items: center;
  border-radius: 5px;
  color: #000;
  /* font-family: "SF Pro Text"; */
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: auto;
  margin-top: 15px;
  margin-bottom: 5px;
}


.rating-star-black{
  display: flex;
/* height: 25px; */
padding: 0px 2px;

flex-direction: column;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
}

.sort-filter{
  padding: 5px 0px;
  padding-bottom: 0px;
}

.review-simple-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fff; */
}

.review-simple-container .review-sub-simple-popup {
  position: relative;
  height: 70vh;
  width: 850px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2em;
}

.review-simple-container .review-sub-simple-popup .popup-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.review-simple-container .review-sub-simple-popup .popup-content .icon {
  color: #000;
  font-family: sf-pro-text;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.review-simple-container .review-sub-simple-popup .popup-content .highlight {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
}

.review-simple-container .review-sub-simple-popup .popup-content .description {
  color: #000;
  text-align: center;
  font-family: sf-pro-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.review-simple-container .review-sub-simple-popup .popup-content .center {
  text-align: center;
  padding-top: 15px;
}

.review-simple-container .review-sub-simple-popup .popup-content button {
  background: #242424;
  color: #FFF;
  /* font-family: sf-pro-text; */
  font-size: 12px;

  font-style: normal;
  font-weight: 400;
  width: fit-content;
  padding: 12px 30px;
}

.rcf-product-main-container > *{
  font-family: var(--family-font);
}

.sliders > *{
  font-family: var(--family-font);
}

@media (width <= 400px ) {

  .review-simple-container {
    height: 100vh;
  }

  .review-simple-container .review-sub-simple-popup {
    height: 100%;
    justify-content: center;
  }

}

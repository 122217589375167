/* #### Generated By: http://www.cufonfonts.com #### */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
    @font-face {
    font-family: 'Visby Regular';
    /* font-style: normal;
    font-weight: normal; */
    src: local('Visby Regular'), url('VisbyRegular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Bold Italic'), url('VisbyBold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Bold'), url('VisbyBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Extrabold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Extrabold Italic'), url('VisbyExtrabold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Extrabold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Extrabold'), url('VisbyExtrabold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Heavy Italic'), url('VisbyHeavy Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Heavy'), url('VisbyHeavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Italic'), url('VisbyItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Light Italic'), url('VisbyLight Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Light';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Light'), url('VisbyLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Medium Italic'), url('VisbyMedium Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Medium'), url('VisbyMedium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Semibold Italic'), url('VisbySemibold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Semibold'), url('VisbySemibold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Thin Italic'), url('VisbyThin Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Thin'), url('VisbyThin.woff') format('woff');
    }

    @font-face {
        font-family: 'Georgia';
      
        src: local('Georgia'), url('GeorgiaPro-Regular.ttf') format('ttf');
        }
        @font-face {
            font-family: 'Georgia Bold';
          
            src: local('Georgia Bold'), url('GeorgiaPro-Bold.ttf') format('ttf');
            }
            @font-face {
                font-family: 'Georgia Semi Bold';
              
                src: local('Georgia Semi Bold'), url('GeorgiaPro-Semibold.ttf') format('ttf');
                }@font-face {
                    font-family: 'Panton Caps'; 
                    src: url(https://cdn.shopify.com/s/files/1/0539/5444/5491/t/2/assets/Panton-BlackCaps.woff2);
                    font-weight: 700;
                    font-style: normal;
                    font-display: swap;
                  }